import { useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { cx } from '@linaria/core';
import { Button } from 'components/deprecated/Button';
import { IconSearch } from 'components/Icons';
import { wrapper, inputWrapper, input, button } from './NewSearch.styles';

export interface SearchProps extends React.HTMLAttributes<HTMLDivElement> {}

const ENTER_KEY_STRING = 'Enter';

export const NewSearch: React.FC<SearchProps> = (): JSX.Element => {
  const router = useRouter();
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleSearch = useCallback(() => {
    navigateToModelSearch(searchKeyword);
  }, [searchKeyword]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === ENTER_KEY_STRING) {
        navigateToModelSearch(searchKeyword);
      }
    },
    [searchKeyword],
  );

  const navigateToModelSearch = (value: string): void => {
    const encodedValue = encodeURIComponent(value);
    router.push(`/explore/models${encodedValue ? `?searchQuery=${encodedValue}` : ''}`);
  };

  return (
    <div className={cx(wrapper)}>
      <div className={inputWrapper}>
        <Button aria-label="Search" kind="translucent" onClick={handleSearch} className={button} data-testid="searchBtn">
          <IconSearch size={20} color="#667085" />
        </Button>
        <input
          type="text"
          placeholder="Search AI models ..."
          className={input}
          onChange={({ target }) => setSearchKeyword(target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
    </div>
  );
};
