import { FC } from 'react';
import { useRouter, NextRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { cx, LinariaClassName } from '@linaria/core';
import { Link } from 'components/Link';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { navLink__Active, navLink__Radius, navLink__Text } from 'components/Navbar/Navbar.styles';
import { navTabsWrapper, navTabsList, navTab } from './styles.new';

export type EntityTab = {
  url: string;
  text: string;
  testid?: string;
  isActiveClbck?: (router: NextRouter) => boolean;
};

export interface EntityTabListProps {
  makeTabList?: typeof makeDefaultTabList;
  className?: LinariaClassName;
}

interface NavTabProps {
  tabItem: EntityTab;
}

const datasetText = 'Datasets';

const makeDefaultTabList = (): EntityTab[] => [
  {
    url: '/explore',
    text: 'Explore',
  },
  {
    url: '/explore/models',
    text: 'Models',
  },
  {
    url: '/explore/workflows',
    text: 'Workflows',
  },
  {
    url: '/explore/apps',
    text: 'Apps / Templates',
    isActiveClbck: (router) => {
      return router.pathname.includes('/explore/apps');
    },
  },
  {
    url: '/explore/datasets',
    text: datasetText,
  },
  {
    url: '/explore/modules',
    text: 'Modules',
  },
];

const NavTab: React.FC<NavTabProps> = ({ tabItem }) => {
  const router = useRouter();

  const isTabActive = tabItem.isActiveClbck ? tabItem.isActiveClbck(router) : router.asPath.split('?')[0] === tabItem.url;
  const viewMode = router.query.viewMode;
  const href = `${tabItem.url}${viewMode ? `?viewMode=${encodeURIComponent(viewMode as string)}` : ''}`;

  return (
    <Link href={href} data-testid={tabItem.testid || ''}>
      <div
        role="listitem"
        key={tabItem.url}
        className={cx('nav-tab-list-item', navLink__Active, navLink__Radius, navLink__Text, isTabActive && 'active', navTab)}
        data-active={isTabActive}
      >
        {tabItem.text}
      </div>
    </Link>
  );
};

export const NewNavTabs: FC<EntityTabListProps> = observer(({ makeTabList = makeDefaultTabList, className, ...rest }) => {
  const { 'enable-dataset-listing': enableDatasetListing } = useFeatureFlagsLD();
  const tabList = makeTabList().filter((tabItem) => (tabItem.text === datasetText ? enableDatasetListing : true));

  return (
    <nav className={cx(navTabsWrapper, className)} {...rest}>
      <div className={navTabsList}>
        {tabList.map((tabItem) => (
          <NavTab key={tabItem.url} tabItem={tabItem} />
        ))}
      </div>
    </nav>
  );
});
