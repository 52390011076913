import { Link } from 'components/Link';
import { textRegular } from 'styles/typography';
import { FC, ReactNode } from 'react';
import { extras, heading, wrapper, headingText } from './SectionWithTitle.styles';

type SectionWithTitleProps = {
  title: string;
  link?: {
    title: string;
    url: string;
  };
  children: ReactNode;
};

export const SectionWithTitle: FC<SectionWithTitleProps> = ({ title, link, children }) => {
  return (
    <section className={wrapper}>
      <div role="heading" className={heading} aria-level={1}>
        <span className={headingText}>{title}</span>
        {link && (
          <div className={extras}>
            <Link href={link.url} className={textRegular}>
              {link.title}
            </Link>
          </div>
        )}
      </div>
      {children}
    </section>
  );
};
